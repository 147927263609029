import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, merge, reduce, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cabinet, LowCabinet } from '../models/Cabinet';
import { ImageEntryEx } from '../models/Image';
import { Template } from '../models/Template';
import { Evaluation, LowEvaluation } from '../models/Evaluation';
import { Key, NgxIndexedDBService } from 'ngx-indexed-db';

// interface IdxedCabinetImg {
//   _id: string;
//   cabinetId: string;
//   imgFile: File;
// }

// interface IdxedEvaluationImg {
//   _id: string;
//   evaluationId: string;
//   imgFile: File;
// }

@Injectable({
  providedIn: 'root',
})
export class AssessmentSyncService {
  private apiUrl = environment.riskApi;
  private updatedCabinetsDone = new BehaviorSubject<boolean>(true);
  private updatedTemplatesDone = new BehaviorSubject<boolean>(true);
  private updatedEvaluationsDone = new BehaviorSubject<boolean>(true);
  private createdCabinetsDone = new BehaviorSubject<boolean>(true);
  private createdEvaluationsDone = new BehaviorSubject<boolean>(true);
  private deletedCabinetsDone = new BehaviorSubject<boolean>(true);
  private deletedEvaluationsDone = new BehaviorSubject<boolean>(true);
  // private deletedCabinetImagesDone = new BehaviorSubject<boolean>(true);
  // private deletedEvaluationImagesDone = new BehaviorSubject<boolean>(true);
  private deletedImagesDone = new BehaviorSubject<boolean>(true);
  // private createdImagesDone = new BehaviorSubject<boolean>(true);

  public isOnline$ = new BehaviorSubject<boolean>(window.navigator.onLine);
  public isInSync$ = new BehaviorSubject<boolean>(false);
  public syncFinished$ = new BehaviorSubject<boolean>(false);
  private syncStarted = false;

  constructor(
    private offlineDb: NgxIndexedDBService,
    private http: HttpClient,
  ) {
    window.addEventListener('online', () => {
      console.log('SYNC-Service : switch to online-state');
      this.isOnline$.next(true);
      this.startSynchronisation();
    });
    window.addEventListener('offline', () => {
      console.log('SYNC-Service : switch to offline-state');
      this.isOnline$.next(false);
      this.offlineUpdates();
    });

    this.updatedCabinetsDone.subscribe(() => this.offlineUpdates());
    this.updatedTemplatesDone.subscribe(() => this.offlineUpdates());
    this.updatedEvaluationsDone.subscribe(() => this.offlineUpdates());
    this.createdCabinetsDone.subscribe(() => this.offlineUpdates());
    this.createdEvaluationsDone.subscribe(() => this.offlineUpdates());
    this.deletedCabinetsDone.subscribe(() => this.offlineUpdates());
    this.deletedEvaluationsDone.subscribe(() => this.offlineUpdates());
    // this.deletedCabinetImagesDone.subscribe(() => this.offlineUpdates());
    // this.deletedEvaluationImagesDone.subscribe(() => this.offlineUpdates());
    this.deletedImagesDone.subscribe(() => this.offlineUpdates());
    // this.createdImagesDone.subscribe(() => this.offlineUpdates());

    this.startSynchronisation();
    this.syncFinished$.subscribe((finished) => {
      console.log('Synchronisation finished:', finished);
      if (finished) {
        console.warn('syncFinished$.subscribe() finished:', finished);
        this.syncStarted = false;
      }
    });
  }

  public startSynchronisation(): void {
    this.checkForOfflineUpdates().subscribe((done) => {
      if (done && this.offlineUpdates() && this.isOnline$.value) {
        this.syncStarted = true;
        this.isInSync$.next(true);
        console.log('SYNC is starting (check is done)');
        // ---------- new Cabinets --------------------- //
        if (!this.createdCabinetsDone.value) {
          console.log('SYNC new Cabinets available.');
          this.offlineDb
            .getAll<LowCabinet>('Cabinet_created')
            .subscribe((offlineCabs) => {
              try {
                console.log(`SYNC new Cabints > offline`, offlineCabs?.length);
                const cabinetSet: LowCabinet[] = [];
                offlineCabs.forEach((offCab) => {
                  console.log(`SYNC new Cabinets > forEach:`, offCab);
                  const newCab = {
                    Uid: offCab.Uid,
                    Anlage: offCab.Anlage,
                    Images: [], // für offline erzeugte nicht möglich
                  } as LowCabinet;
                  cabinetSet.push(newCab);
                });
                console.log(
                  `SYNC new Cabinets > POST /cabinets/amount :`,
                  cabinetSet?.length,
                );

                this.http
                  .post<Cabinet[]>(`${this.apiUrl}/cabinets/amount`, cabinetSet)
                  .subscribe((onResCabs) => {
                    console.log(
                      `SYNC new Cabinets > result[${onResCabs.length}]`,
                    );
                    if (cabinetSet.length == onResCabs.length) {
                      const idsDel = offlineCabs
                        .filter((c) => c._id !== undefined)
                        .map((c) => c._id) as Key[];
                      const resIds = onResCabs.map((c) => c._id);
                      console.log(
                        `SYNC new Cabinets > cabinets_created`,
                        resIds,
                      );
                      this.offlineDb.bulkPut<Cabinet>('Cabinet', onResCabs);
                      this.offlineDb
                        .bulkDelete('Cabinet_created', idsDel)
                        .subscribe(() => this.checkForOfflineUpdates());
                    } else {
                      console.error(
                        `SYNC new Cabinets > cabinetSet.length[${cabinetSet.length}] !== cabinets.length[${onResCabs.length}]`,
                      );
                    }
                  });
              } catch (err) {
                console.error('SYNC new Cabinets > ERROR:', err);
              }
            });
        }
        // ---------- updated Cabinets (incl. Imgs)----- //
        if (!this.updatedCabinetsDone.value) {
          console.log('SYNC updated Cabinets available.');
          this.offlineDb
            .getAll<ImageEntryEx>('Cabinet_updated')
            .subscribe((resultSet) => {
              const idsCabinets = resultSet.map((c) => c._id);
              const cobinetsWithImgs = resultSet.filter(
                (c) => c._id !== undefined && c.rawImage !== undefined,
              );
              console.log(
                `SYNC:cabinets updated [${idsCabinets.length}]`,
                idsCabinets,
              );
              this.offlineDb
                .bulkGet<Cabinet>('Cabinet', idsCabinets)
                .subscribe((resCabinets: any) => {
                  if (resCabinets) {
                    console.log(
                      `SYNC:cabinets from Offline data:`,
                      resCabinets,
                    );
                    this.http
                      .patch<
                        Cabinet[]
                      >(`${this.apiUrl}/cabinets/amount/`, resCabinets)
                      .subscribe((httpCabinets) => {
                        if (resCabinets.length == httpCabinets.length) {
                          console.log(
                            `SYNC:cabinets_updated [${httpCabinets.length}] ids:`,
                            httpCabinets,
                          );
                          this.offlineDb
                            .bulkDelete('Cabinet_updated', idsCabinets)
                            .subscribe(() => this.checkForOfflineUpdates());
                        } else {
                          console.error(
                            `SYNC: offline[${resCabinets.length}] !== online[${httpCabinets.length}] ids:`,
                            httpCabinets,
                          );
                        }
                      });
                  } else {
                    console.warn(
                      `SYNC:cabinets from Offline data (UNDEFINDED ??) :`,
                      resCabinets,
                    );
                  }
                });
              // new images
              let successResponse = 0;
              let failerResponse = 0;
              for (let i = 0; i < cobinetsWithImgs.length; i += 1) {
                const imgCabinet = cobinetsWithImgs[i];
                console.log(
                  `SYNC [${cobinetsWithImgs.length}] updated Cabinets with Images available.`,
                );
                this.offlineDb
                  .getByID<ImageEntryEx>('Cabinet_updated', imgCabinet._id)
                  .subscribe((localCabinet) => {
                    console.log(localCabinet);
                    if (localCabinet && imgCabinet.parentId) {
                      this.offlineDb
                        .getByID<Cabinet>('Cabinet', imgCabinet.parentId)
                        .subscribe((cabinet) => {
                          console.log(
                            `SYNC for [${localCabinet._id}] updated Cabinet with Images available.`,
                            imgCabinet.rawImage,
                          );
                          const formData = new FormData();
                          formData.append('file', imgCabinet.rawImage);
                          formData.append('fileName', imgCabinet.rawImage.name);
                          formData.append(
                            'cabinet',
                            JSON.stringify(cabinet as Cabinet),
                          );
                          this.http
                            .post(`${this.apiUrl}/cabinets/images/`, formData, {
                              reportProgress: true,
                              observe: 'events',
                            })
                            .subscribe((event) => {
                              if (event.type == HttpEventType.Response) {
                                if (
                                  typeof event.body == typeof HttpErrorResponse
                                ) {
                                  failerResponse += 1;
                                }
                                if (event.body !== null) {
                                  successResponse += 1;
                                  this.offlineDb
                                    .deleteByKey(
                                      'Cabinet_updated',
                                      imgCabinet._id,
                                    )
                                    .subscribe(() =>
                                      this.checkForOfflineUpdates(),
                                    );
                                }
                              }
                            });
                        });
                    }
                  });
              }
            });
        }
        // ---------- deleted Cabinets ----------------- //
        if (!this.deletedCabinetsDone.value) {
          console.log('SYNC deleted Cabinets available.');
          this.offlineDb
            .getAll<{ _id: string }>('Cabinet_deleted')
            .subscribe((result) => {
              console.log(`SYNC:Cabinet_deleted result[${result.length}]`);
              result.forEach((idObj) => {
                this.http
                  .delete<Cabinet>(`${this.apiUrl}/cabinets/${idObj._id}`)
                  .subscribe((cabinets) => {
                    console.log('SYNC:Cabinet_deleted http-result', cabinets);
                    this.offlineDb
                      .deleteByKey('Cabinet_deleted', idObj._id)
                      .subscribe((del) => {
                        console.log(
                          `SYNC:Cabinet_deleted offline-result [${idObj._id}]`,
                          del,
                        );
                      });
                    this.checkForOfflineUpdates();
                  });
              });
            });
        }
        // ---------- deleted CabinetImages ------------ //
        // if (!this.deletedCabinetImagesDone.value) {
        //   console.log('SYNC deleted CabinetImages available.');
        //   this.offlineDb
        //     .getAll<{ _id: string; imgId: string }>('CabinetImage_deleted')
        //     .subscribe((result) => {
        //       result?.forEach((element) => {
        //         this.http
        //           .delete<Cabinet>(
        //             `${this.apiUrl}/cabinets/images/${element.imgId}`,
        //           )
        //           .subscribe((cabinet) => {
        //             this.offlineDb.update<Cabinet>('Cabinet', cabinet);
        //             this.offlineDb
        //               .delete('CabinetImage_deleted', element._id)
        //               .subscribe((res) => {
        //                 console.log(
        //                   'SYNC deleted CabinetImages finished: now available:',
        //                   res.length,
        //                 );
        //                 if (res.length == 0) {
        //                   // this.deletedCabinetImagesDone = true;
        //                   this.checkForOfflineUpdates();
        //                 }
        //               });
        //           });
        //       });
        //     });
        // }
        // ---------- new Evaluations ------------------ //
        if (!this.createdEvaluationsDone.value) {
          console.log('SYNC new Evaluations available.');
          this.offlineDb
            .getAll<Evaluation>('Evaluation_created')
            .subscribe((offlineEvas) => {
              try {
                console.log(
                  `SYNC new Evaluations > offline`,
                  offlineEvas?.length,
                );
                const evaluationSet: LowEvaluation[] = [];
                offlineEvas.forEach((offEva) => {
                  console.log('SYNC new Evaluations > forEach', offEva);
                  const newEva = {
                    Name: offEva.Name,
                    Cabinet: offEva.Cabinet,
                    Page1: offEva.Page1,
                    Page2: offEva.Page2,
                    PDFResult: offEva.PDFResult,
                    Pruefung: offEva.Pruefung,
                    Gefaehrdung: offEva.Gefaehrdung,
                    // createdAt: offEva.createdAt,
                    // updatedAt: offEva.updatedAt,
                    Images: [],
                  } as LowEvaluation;
                  evaluationSet.push(newEva);
                });

                this.http
                  .post<
                    Evaluation[]
                  >(`${this.apiUrl}/evaluations/amount`, evaluationSet)
                  .subscribe((onlineRes) => {
                    console.log(
                      'SYNC new Evaluations finished: now available:',
                      onlineRes.length,
                    );
                    if (offlineEvas.length == onlineRes.length) {
                      const ids = offlineEvas
                        .filter((c) => c._id !== undefined)
                        .map((c) => c._id) as Key[];
                      console.log(`SYNC:evaluations_created ids:`, ids);
                      this.offlineDb.bulkPut<Evaluation>(
                        'Evaluation',
                        onlineRes,
                      );
                      this.offlineDb
                        .bulkDelete('Evaluation_created', ids)
                        .subscribe(() => this.checkForOfflineUpdates());
                    }
                  });
              } catch (err) {
                console.error('SYNC new Evaluations > ERROR:', err);
              }
            });
        }
        // ---------- updated Evaluations (incl. Imgs)-- //
        if (!this.updatedEvaluationsDone.value) {
          console.log('SYNC updated Evaluations available.');
          this.offlineDb
            .getAll<ImageEntryEx>('Evaluation_updated')
            .subscribe((resultSet) => {
              const idsEvaluation = resultSet.map((e) => e._id);
              const evaluationsWithImgs = resultSet.filter(
                (c) => c._id !== undefined && c.rawImage !== undefined,
              );
              console.log(
                `SYNC:evaluations without images [${idsEvaluation.length}]`,
                idsEvaluation,
              );
              this.offlineDb
                .bulkGet<Evaluation>('Evaluation', idsEvaluation)
                .subscribe((resEvaluations: any) => {
                  if (resEvaluations) {
                    console.log(
                      `SYNC:evaluations from Offline data:`,
                      resEvaluations,
                    );
                    this.http
                      .patch<
                        Evaluation[]
                      >(`${this.apiUrl}/evaluations/amount/`, resEvaluations)
                      .subscribe((results) => {
                        if (resEvaluations?.length == results.length) {
                          console.log(
                            `SYNC:evaluations_updated [${results.length}] ids:`,
                          );
                          this.offlineDb
                            .bulkDelete('Evaluation_updated', idsEvaluation)
                            .subscribe(() => this.checkForOfflineUpdates());
                        } else {
                          console.error(
                            `SYNC: offline[${resEvaluations.length}] !== online[${results.length}] ids:`,
                            results,
                          );
                        }
                      });
                  } else {
                    console.warn(
                      `SYNC:cabinets from Offline data (UNDEFINDED ??) :`,
                      resEvaluations,
                    );
                  }
                });
              // new images
              let successResponse = 0;
              let failerResponse = 0;
              for (let i = 0; i < evaluationsWithImgs.length; i += 1) {
                const imgEvaluation = evaluationsWithImgs[i];
                console.log(
                  `SYNC [${evaluationsWithImgs.length}] updated Evaluations with Images available.`,
                );
                this.offlineDb
                  .getByID<ImageEntryEx>(
                    'Evaluation_updated',
                    imgEvaluation._id,
                  )
                  .subscribe((localEvaluation) => {
                    console.log(localEvaluation);
                    if (localEvaluation && imgEvaluation.parentId) {
                      this.offlineDb
                        .getByID<Evaluation>(
                          'Evaluation',
                          imgEvaluation.parentId,
                        )
                        .subscribe((evaluation) => {
                          console.log(
                            `SYNC for [${localEvaluation._id}] updated Evaluation with Images available.`,
                            imgEvaluation.rawImage,
                          );
                          const formData = new FormData();
                          formData.append('file', imgEvaluation.rawImage);
                          formData.append(
                            'fileName',
                            imgEvaluation.rawImage.name,
                          );
                          formData.append(
                            'evaluation',
                            JSON.stringify(evaluation as Evaluation),
                          );
                          this.http
                            .post(
                              `${this.apiUrl}/evaluations/images/`,
                              formData,
                              {
                                reportProgress: true,
                                observe: 'events',
                              },
                            )
                            .subscribe((event) => {
                              if (event.type == HttpEventType.Response) {
                                if (
                                  typeof event.body == typeof HttpErrorResponse
                                ) {
                                  failerResponse += 1;
                                }
                                if (event.body !== null) {
                                  successResponse += 1;
                                  this.offlineDb
                                    .deleteByKey(
                                      'Evaluation_updated',
                                      imgEvaluation._id,
                                    )
                                    .subscribe(() =>
                                      this.checkForOfflineUpdates(),
                                    );
                                }
                              }
                            });
                        });
                    }
                  });
              }
            });
        }
        // ---------- deleted Evaluations -------------- //
        if (!this.deletedEvaluationsDone.value) {
          console.log('SYNC deleted Evaluations available.');
          this.offlineDb
            .getAll<{ _id: string }>('Evaluation_deleted')
            .subscribe((result) => {
              console.log(`SYNC: Evaluation_deleted result[${result.length}]`);
              result.forEach((idObj) => {
                this.http
                  .delete<Evaluation>(`${this.apiUrl}/evaluations/${idObj._id}`)
                  .subscribe(() => {
                    this.offlineDb
                      .deleteByKey('Evaluation_deleted', idObj._id)
                      .subscribe(() => this.checkForOfflineUpdates());
                  });
                // map((evaluations) => {
                //     this.offlineDb.deleteByKey('Evaluation_deleted', idObj._id);
                //     // this.deletedEvaluationsDone = true;
                //     this.checkForOfflineUpdates();
                //   }),
                // );
              });
            });
        }
        // ---------- deleted EvaluationImages --------- //
        // if (!this.deletedEvaluationImagesDone.value) {
        //   console.log('SYNC deleted EvaluationImages available.');
        //   this.offlineDb
        //     .getAll<{ _id: string; imgId: string }>('EvaluationImage_deleted')
        //     .subscribe((result) => {
        //       result?.forEach((element) => {
        //         this.http
        //           .delete<Evaluation>(
        //             `${this.apiUrl}/evaluations/images/${element.imgId}`,
        //           )
        //           .subscribe((evaluation) => {
        //             this.offlineDb.update<Evaluation>('Evaluation', evaluation);
        //             this.offlineDb
        //               .delete('EvaluationImage_deleted', element._id)
        //               .subscribe(() => this.checkForOfflineUpdates());
        //           });
        //       });
        //     });
        // }
        // ---------- updated Templates ---------------- //
        if (!this.updatedTemplatesDone.value) {
          console.log('SYNC updated Templates available.');
          this.offlineDb
            .getAll<{ _id: string }>('Template_updated')
            .subscribe((resultSet) => {
              const idsTemplates = resultSet
                .filter((c) => c._id !== undefined)
                .map((c) => c._id);
              console.log(
                `SYNC:templates  [${idsTemplates.length}]`,
                idsTemplates,
              );
              this.offlineDb
                .bulkGet('Template', idsTemplates)
                .subscribe((resTemplates: unknown) => {
                  console.log(
                    `SYNC:templates from Offline data:`,
                    resTemplates,
                  );
                  this.http
                    .patch<
                      Template[]
                    >(`${this.apiUrl}/templates/amount/`, resTemplates)
                    .subscribe((result) => {
                      if ((resTemplates as []).length == result.length) {
                        console.log(
                          `SYNC:templates_updated [${result.length}] ids:`,
                        );
                        this.offlineDb.bulkDelete(
                          'Template_updated',
                          idsTemplates,
                        );
                        this.checkForOfflineUpdates();
                      }
                    });
                });
            });
        }
        // ---------- deleted Images ------------ //
        if (!this.deletedImagesDone.value) {
          console.log('SYNC deleted CabinetImages available.');
          this.offlineDb
            .getAll<{ _id: string; imgId: string }>('CabinetImage_deleted')
            .subscribe((result) => {
              result?.forEach((element) => {
                this.http
                  .delete<Cabinet>(`${this.apiUrl}/images/${element.imgId}`)
                  .subscribe((cabinet) => {
                    this.offlineDb.update<Cabinet>('Cabinet', cabinet);
                    this.offlineDb
                      .delete('Image_deleted', element._id)
                      .subscribe((res) => {
                        console.log(
                          'SYNC deleted CabinetImages finished: now available:',
                          res.length,
                        );
                        if (res.length == 0) {
                          // this.deletedCabinetImagesDone = true;
                          this.checkForOfflineUpdates();
                        }
                      });
                  });
              });
            });
        }
      }
    });

    // function checkNewCabinets(
    //   service: AssessmentSyncService,
    //   // done: boolean,
    //   // offlineDb: NgxIndexedDBService,
    //   // http: HttpClient,
    // ) {
    //   // ---------- new Cabinets ------------------ //
    //   if (!service.createdCabinetsDone.value) {
    //     console.log('SYNC new Cabinets available.');
    //     service.offlineDb
    //       .getAll<LowCabinet>('Cabinet_created')
    //       .subscribe((offlineCabs) => {
    //         try {
    //           console.log(`SYNC new Cabints > offline`, offlineCabs?.length);
    //           const cabinetSet: LowCabinet[] = [];
    //           offlineCabs.forEach((offCab) => {
    //             console.log(`SYNC new Cabinets > forEach:`, offCab);
    //             const newCab = {
    //               Uid: offCab.Uid,
    //               Anlage: offCab.Anlage,
    //               Images: [], // für offline erzeugte nicht möglich
    //             } as LowCabinet;
    //             cabinetSet.push(newCab);
    //           });
    //           console.log(
    //             `SYNC new Cabinets > POST /cabinets/amount :`,
    //             cabinetSet?.length,
    //           );

    //           service.http
    //             .post<Cabinet[]>(
    //               `${service.apiUrl}/cabinets/amount`,
    //               cabinetSet,
    //             )
    //             .subscribe((onResCabs) => {
    //               console.log(
    //                 `SYNC new Cabinets > result[${onResCabs.length}]`,
    //               );
    //               if (cabinetSet.length == onResCabs.length) {
    //                 const idsDel = offlineCabs
    //                   .filter((c) => c._id !== undefined)
    //                   .map((c) => c._id) as Key[];
    //                 const resIds = onResCabs.map((c) => c._id);
    //                 console.log(`SYNC new Cabinets > cabinets_created`, resIds);
    //                 service.offlineDb.bulkPut<Cabinet>('Cabinet', onResCabs);
    //                 service.offlineDb
    //                   .bulkDelete('Cabinet_created', idsDel)
    //                   .subscribe(() => service.checkForOfflineUpdates());
    //               } else {
    //                 console.error(
    //                   `SYNC new Cabinets > cabinetSet.length[${cabinetSet.length}] !== cabinets.length[${onResCabs.length}]`,
    //                 );
    //               }
    //             });
    //         } catch (err) {
    //           console.error('SYNC new Cabinets > ERROR:', err);
    //         }
    //       });
    //   }
    // }

    // function checkUpdatedCabinets(service: AssessmentSyncService) {
    //   // ---------- updated Cabinets -------------- //
    //   if (!service.updatedCabinetsDone.value) {
    //     console.log('SYNC updated Cabinets available.');
    //     service.offlineDb
    //       .getAll<ImageEntry>('Cabinet_updated')
    //       .subscribe((resultSet) => {
    //         const cobinetsWithImgs = resultSet.filter(
    //           (c) =>
    //             (c as ImageEntry)._id !== undefined &&
    //             (c as ImageEntry).imgFile !== undefined,
    //         );
    //         const idsCabinets = resultSet
    //           .filter(
    //             (c) =>
    //               (c as ImageEntry)._id !== undefined &&
    //               (c as ImageEntry).imgFile === undefined,
    //           )
    //           .map((c) => (c as ImageEntry)._id);
    //         console.log(
    //           `SYNC:cabinets without images [${idsCabinets.length}]`,
    //           idsCabinets,
    //         );
    //         service.offlineDb
    //           .bulkGet('Cabinet', idsCabinets)
    //           .subscribe((resCabinets: unknown) => {
    //             console.log(`SYNC:cabinets from Offline data:`, resCabinets);
    //             service.http
    //               .patch<Cabinet[]>(
    //                 `${service.apiUrl}/cabinets/amount/`,
    //                 resCabinets,
    //               )
    //               .subscribe((httpCabinets) => {
    //                 if ((resCabinets as []).length == httpCabinets.length) {
    //                   console.log(
    //                     `SYNC:cabinets_updated [${httpCabinets.length}] ids:`,
    //                   );
    //                   service.offlineDb.bulkDelete(
    //                     'Cabinet_updated',
    //                     idsCabinets,
    //                   );
    //                   // this.updatedCabinetsDone = true;
    //                   service.checkForOfflineUpdates();
    //                 }
    //               });
    //           });
    //         // this.createdCabinetImagesDone =
    //         //   cobinetsWithImgs.length > 0 ? false : true;
    //         let successResponse = 0;
    //         let failerResponse = 0;
    //         for (let i = 0; i < cobinetsWithImgs.length; i += 1) {
    //           const imgCabinet = cobinetsWithImgs[i];
    //           console.log(
    //             `SYNC [${cobinetsWithImgs.length}] updated Cabinets with Images available.`,
    //           );
    //           service.offlineDb
    //             .getByID('Cabinet', imgCabinet._id)
    //             .subscribe((localCabinet) => {
    //               const formData = new FormData();
    //               formData.append('file', imgCabinet.imgFile);
    //               formData.append('fileName', imgCabinet.imgFile.name);
    //               formData.append(
    //                 'cabinet',
    //                 JSON.stringify(localCabinet as Cabinet),
    //               );
    //               service.http
    //                 .post(`${service.apiUrl}/cabinets/images/`, formData, {
    //                   reportProgress: true,
    //                   observe: 'events',
    //                 })
    //                 .subscribe((event) => {
    //                   if (event.type == HttpEventType.Response) {
    //                     if (typeof event.body == typeof HttpErrorResponse) {
    //                       failerResponse += 1;
    //                     }
    //                     if (event.body !== null) {
    //                       successResponse += 1;
    //                     }
    //                     if (
    //                       i == cobinetsWithImgs.length - 1 &&
    //                       i == successResponse
    //                     ) {
    //                       // this.createdCabinetImagesDone = true;
    //                       service.checkForOfflineUpdates();
    //                     }
    //                   }
    //                 });
    //             });
    //         }
    //       });
    //   }
    // }

    // function checkNewEvaluations() {
    //   // ---------- new Evaluations --------------- //
    // }
  }

  private checkForOfflineUpdates(): BehaviorSubject<boolean> {
    const result = new BehaviorSubject<boolean>(false);
    let uCa = false;
    let uTe = false;
    let uEv = false;
    let cCa = false;
    let cEv = false;
    let dCa = false;
    let dEv = false;
    let dIm = false;
    // let dEI = false;
    const syncFinished = this.syncFinished$;
    let syncStarted = this.syncStarted;

    function done(): boolean {
      const done = uCa && uTe && uEv && cCa && cEv && dCa && dEv && dIm;
      if (syncStarted && done) {
        console.log('Synchronisation is finished!');
        syncStarted = false;
        syncFinished.next(true);
      }
      return done;
    }

    // result.next(done());
    this.offlineDb.getAll('Cabinet_created').subscribe((cc) => {
      const exists: boolean = cc && cc?.length > 0;
      this.createdCabinetsDone.next(!exists);
      if (exists) console.log('CHECK Cabinet_created:', cc.length);
      cCa = true;
      result.next(done());
    });
    this.offlineDb.getAll('Cabinet_updated').subscribe((cu) => {
      this.updatedCabinetsDone.next(!(cu && cu?.length > 0));
      if (cu?.length > 0) console.log('CHECK Cabinet_updated:', cu.length);
      uCa = true;
      result.next(done());
    });

    this.offlineDb.getAll('Evaluation_created').subscribe((ec) => {
      this.createdEvaluationsDone.next(!(ec && ec?.length > 0));
      if (ec?.length > 0) console.log('CHECK Evaluation_created:', ec.length);
      cEv = true;
      result.next(done());
    });
    this.offlineDb.getAll('Evaluation_updated').subscribe((eu) => {
      this.updatedEvaluationsDone.next(!(eu && eu?.length > 0));
      if (eu?.length > 0) console.log('CHECK Evaluation_updated:', eu.length);
      uEv = true;
      result.next(done());
    });

    this.offlineDb.getAll('Template_updated').subscribe((tu) => {
      this.updatedTemplatesDone.next(!(tu && tu?.length > 0));
      if (tu?.length > 0) console.log('CHECK Template_updated:', tu.length);
      uTe = true;
      result.next(done());
    });

    this.offlineDb.getAll('Cabinet_deleted').subscribe((cd) => {
      const exists: boolean = cd && cd?.length > 0;
      this.deletedCabinetsDone.next(!exists);
      if (exists) console.log('CHECK Cabinet_deleted:', cd.length);
      dCa = true;
      result.next(done());
    });
    // this.offlineDb.getAll('CabinetImage_deleted').subscribe((cid) => {
    //   this.deletedCabinetImagesDone.next(!(cid && cid?.length > 0));
    //   if (cid?.length > 0)
    //     console.log('CHECK CabinetImage_deleted:', cid.length);
    //   dCI = true;
    //   result.next(done());
    // });
    this.offlineDb.getAll('Evaluation_deleted').subscribe((ed) => {
      this.deletedEvaluationsDone.next(!(ed && ed?.length > 0));
      if (ed?.length > 0) console.log('CHECK Evaluation_deleted:', ed.length);
      dEv = true;
      result.next(done());
    });
    // this.offlineDb.getAll('EvaluationImage_deleted').subscribe((eid) => {
    //   this.deletedEvaluationImagesDone.next(!(eid && eid?.length > 0));
    //   if (eid?.length > 0)
    //     console.log('CHECK EvaluationImage_deleted:', eid.length);
    //   dEI = true;
    //   result.next(done());
    // });
    this.offlineDb.getAll('Image_deleted').subscribe((id) => {
      this.deletedImagesDone.next(!(id && id?.length > 0));
      if (id?.length > 0) console.log('CHECK Image_deleted:', id.length);
      dIm = true;
      result.next(done());
    });
    // this.offlineDb.getAll('Image_created').subscribe((id) => {
    //   this.createdImagesDone.next(!(id && id?.length > 0));
    //   if (id?.length > 0)
    //     console.log('CHECK Image_created:', id.length);
    //   dCI = true;
    //   result.next(done());
    // });

    return result;
  }

  offlineUpdates() {
    const updates =
      !this.updatedCabinetsDone.value ||
      !this.updatedTemplatesDone.value ||
      !this.updatedEvaluationsDone.value ||
      !this.deletedCabinetsDone.value ||
      !this.deletedEvaluationsDone.value ||
      // !this.deletedCabinetImagesDone.value ||
      // !this.deletedEvaluationImagesDone.value ||
      !this.deletedImagesDone.value ||
      !this.createdCabinetsDone.value ||
      !this.createdEvaluationsDone.value;
    this.isInSync$.next(updates && this.isOnline$.value);
    return updates;
  }

  public clearOfflineData() {
    this.offlineDb
      .clear('Image')
      .subscribe((r) => console.log('IndexedDb.Image cleared:', r));
    this.offlineDb
      .clear('Image_deleted')
      .subscribe((r) => console.log('IndexedDb.Image_deleted cleared:', r));

    this.offlineDb
      .clear('Cabinet')
      .subscribe((r) => console.log('IndexedDb.Cabinet cleared:', r));
    this.offlineDb
      .clear('Cabinet_created')
      .subscribe((r) => console.log('IndexedDb.Cabinet_created cleared:', r));
    this.offlineDb
      .clear('Cabinet_deleted')
      .subscribe((r) => console.log('IndexedDb.Cabinet_deleted cleared:', r));
    this.offlineDb
      .clear('Cabinet_updated')
      .subscribe((r) => console.log('IndexedDb.Cabinet_updated cleared:', r));
    // this.offlineDb
    //   .clear('CabinetImage')
    //   .subscribe((r) => console.log('IndexedDb.CabinetImage cleared:', r));
    // this.offlineDb
    //   .clear('CabinetImage_deleted')
    //   .subscribe((r) =>
    //     console.log('IndexedDb.CabinetImage_deleted cleared:', r),
    //   );
    this.offlineDb
      .clear('Cabinet_importFiles')
      .subscribe((r) =>
        console.log('IndexedDb.Cabinet_importFiles cleared:', r),
      );
    this.offlineDb
      .clear('Evaluation')
      .subscribe((r) => console.log('IndexedDb.Evaluation cleared:', r));
    this.offlineDb
      .clear('Evaluation_created')
      .subscribe((r) =>
        console.log('IndexedDb.Evaluation_created cleared:', r),
      );
    this.offlineDb
      .clear('Evaluation_deleted')
      .subscribe((r) =>
        console.log('IndexedDb.Evaluation_deleted cleared:', r),
      );
    this.offlineDb
      .clear('Evaluation_updated')
      .subscribe((r) =>
        console.log('IndexedDb.Evaluation_updated cleared:', r),
      );
    // this.offlineDb
    //   .clear('EvaluationImage')
    //   .subscribe((r) => console.log('IndexedDb.EvaluationImage cleared:', r));
    // this.offlineDb
    //   .clear('EvaluationImage_deleted')
    //   .subscribe((r) =>
    //     console.log('IndexedDb.EvaluationImage_deleted cleared:', r),
    //   );
    this.offlineDb
      .clear('Template')
      .subscribe((r) => console.log('IndexedDb.Template cleared:', r));
    this.offlineDb
      .clear('Template_updated')
      .subscribe((r) => console.log('IndexedDb.Template_updated cleared:', r));
  }
}
